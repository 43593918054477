/* styles commun aux pages home et travelbook */

@import "../styles/common.scss";

html,
body {
  height: 100vh; /* indispensable pour occuper toute la hauteur du device */
  margin: 0;
  padding: 0;
}

/* logo chargement */
.loading,
.loadingV {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 400px;
}
.loadingV {
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-container {
  padding: 15px;
  width: 100%;
  margin: 0;

  .requis {
    color: #df0b0b;
    font-weight: bold;
  }
  .btn-primary {
    background-color: #840505;
    border-color: #df0b0b;
  }
}
